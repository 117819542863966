import { Box, Paper, Button, Grid, Link } from "@material-ui/core";
import { STATIC_CART_ITEMS } from "../../../static/cart-items";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const FreeCVI = () => {
  const { COMPREHENSIVE_VEHICLE_INSPECTION } = STATIC_CART_ITEMS;

  return (
    <Grid
      container
      justifyContent={APP_ENV.appBrandId === 4 ? "center" : "flex-start"}
    >
      {[
        {
          image:
            "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/cvi-image.png",
          buttonLabel: "Add To Cart",
          buttonProps: {
            onClick: COMPREHENSIVE_VEHICLE_INSPECTION.addToCart,
          },
        },
        {
          image:
            "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/valvoline-rebate_sept-to-oct.jpg",
          buttonLabel: "See Details",
          buttonProps: {
            component: Link,
            href: "/car-appointment",
            /* target: "_blank", */
          },
        },
      ]
        .filter((promo) => {
          if (
            APP_ENV.appBrandId === 4 &&
            promo.image.includes("valvoline-rebate_sept-to-oct.jpg")
          ) {
            return false;
          }
          return true;
        })
        .map(({ image, buttonLabel, buttonProps }, i) => (
          <Grid
            item
            xs={12}
            md={6}
            style={{
              padding: 25,
              margin: APP_ENV.appBrandId === 4 ? "0 auto" : "inherit",
            }}
            key={i}
          >
            <Paper
              style={{
                width: "fit-content",
                cursor: "pointer",
                textAlign: "center",
              }}
              elevation={8}
            >
              <img
                src={image}
                style={{
                  width: "100%",
                  height: "auto",
                  cursor: "pointer",
                  maxWidth: 750,
                }}
              />
            </Paper>
            <Box style={{ paddingTop: 25, textAlign: "center" }}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={{
                  width: 300,
                  height: 50,
                  color: theme.palette.primary.contrastText,
                }}
                {...buttonProps}
              >
                {buttonLabel}
              </Button>
            </Box>
          </Grid>
        ))}
    </Grid>
  );
};

export default FreeCVI;
