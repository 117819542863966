import { Box, Typography, Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MetaTags from "../components/reusable/ReactMetaTags";
import useOpenTireSizeSelector from "../hooks/util/useOpenTireSizeSelector";
import {
  STATIC_CART_ITEMS,
  CAR_SERVICES_ROUTE_DIRECTORY,
} from "../static/cart-items";
import APP_ENV from "../appEnvironment";
import MainHeroImage from "../components/promotions/full-site-tire-sale/main-hero-image";
import { CouponSecondaryOfferSlider } from "../components/coupons/coupon-secondary-offers";
import SyncronyRebateHero from "../components/promotions/syncrony-rebate/syncrony-rebate-hero";
import SmogCheckHero from "../components/smog-check/smog-check-hero";
import FreeCVI from "../components/promotions/widgets/free-cvi";
import { StandardWidthContainer } from "../components/generic/containers";
import { PROMO_THEME } from "../hooks/store/promotion-store";

const siteSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: APP_ENV.appBrandName,
  url: APP_ENV.appSlug,
  logo: APP_ENV.appBrandLogoImg,
  sameAs: Object.values(APP_ENV.appBrandLinks).filter((i) => i),
};

const { theme } = APP_ENV;

const Home = () => {
  const history = useHistory();

  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: true });

  return (
    <>
      <MetaTags
        description={`${APP_ENV.appBrandName} has the largest online selection of new tires & wheels. Find great deals on oil change, brakes, and wheel alignment. Shop Today!`}
        canonical={"/"}
        title={`${APP_ENV.appBrandName} | Official Online Store | Shop Now & Save`}
        script={JSON.stringify(siteSchema)}
      />

      <MainHeroImage />
      <CouponSecondaryOfferSlider />
      {APP_ENV.appBrandId !== 4 && (
        <StandardWidthContainer
          backgroundColor={theme.palette.background.default}
        >
          <Box
            style={{
              textAlign: "center",
              paddingTop: 25,
            }}
          >
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              {`${PROMO_THEME.sitePromoName} Special Offer`}
            </Typography>
          </Box>
          <FreeCVI />
        </StandardWidthContainer>
      )}
      <SmogCheckHero />
      <SyncronyRebateHero />
      <Box
        style={{
          paddingTop: 25,
          paddingBottom: 25,
          paddingLeft: 15,
          paddingRight: 15,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography
            variant="h1"
            fontWeight="bold"
            style={{ textAlign: "center", padding: 15 }}
          >
            The easiest way to shop car repair online
          </Typography>
          <Grid
            container
            justifyContent={APP_ENV.appBrandId === 4 ? "center" : "flex-start"}
            alignItems={APP_ENV.appBrandId === 4 ? "center" : "flex-start"}
            spacing={APP_ENV.appBrandId === 4 ? 4 : 2}
            style={{
              paddingTop: APP_ENV.appBrandId === 4 ? 40 : 20,
              paddingBottom: APP_ENV.appBrandId === 4 ? 40 : 20,
              textAlign: "center",
            }}
          >
            {[
              [
                "Tires",
                "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/ico-yourtires.svg",
                null,
                openTireSizeModal,
              ],
              [
                "Oil Change",
                "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/oil_icon.svg",
                STATIC_CART_ITEMS.OIL_CONSULTATION?.endPoint,
              ],
              [
                "Brakes",
                "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/auto_icon.svg",
                STATIC_CART_ITEMS.BRAKE_CHECK?.endPoint,
              ],
              [
                "Alignment",
                "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/ico-yourneeds.svg",
                STATIC_CART_ITEMS.WHEEL_ALIGNMENT_CHECK?.endPoint,
              ],
              [
                "Wheels",
                "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/ico-yourvehicle.svg",
                "/wheels",
              ],
              [
                "FREE Services",
                "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/ico-yourappointment.svg",
                CAR_SERVICES_ROUTE_DIRECTORY,
              ],
            ]
              .filter(([label]) => {
                if (
                  APP_ENV.appBrandId === 4 &&
                  (label === "Oil Change" || label === "Brakes")
                ) {
                  return false;
                }
                return true;
              })
              .map(([label, img, endPoint, handleClick = () => null]) => (
                <Grid
                  key={label}
                  item
                  xs={APP_ENV.appBrandId === 4 ? 6 : 6}
                  sm={APP_ENV.appBrandId === 4 ? 6 : 6}
                  md={APP_ENV.appBrandId === 4 ? 6 : 4}
                  lg={APP_ENV.appBrandId === 4 ? 3 : 4}
                  xl={APP_ENV.appBrandId === 4 ? 3 : 4}
                  style={{
                    padding: 25,
                    position: "relative",
                  }}
                  onClick={
                    endPoint ? () => history.push(endPoint) : handleClick
                  }
                  component={Button}
                  elevation={8}
                >
                  <img
                    src={img}
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: 165,
                      margin: "0 auto",
                    }}
                  />
                  <Box
                    style={{
                      position: "absolute",
                      bottom: -3,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {label}
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Home;
