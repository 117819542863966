import { Grid } from "@material-ui/core";
import { StandardWidthContainer } from "../../generic/containers";
import useOpenTireSizeSelector from "../../../hooks/util/useOpenTireSizeSelector";
import { useHistory } from "react-router-dom";
import LayoutStore from "../../../hooks/store/client/layout-store";
import { useLocationStore } from "../../../hooks/store/location-store";
import useAppCreditInformation from "../../../hooks/store/credit-store";
import APP_ENV from "../../../appEnvironment";
import { QUICK_APPOINTMENT_DIRECTORY } from "../../../routes";

const MainHeroImage = () => {
  const [userStore] = useLocationStore((st) => [st.userStore]);

  const history = useHistory();

  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: true });

  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const appCreditInfo = useAppCreditInformation();

  return (
    <>
      {userStore && (
        <StandardWidthContainer>
          <Grid container style={{ marginBottom: 10, cursor: "pointer" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              style={{
                objectFit: "contain",
                paddingTop: 8,
                paddingRight: isMobile ? 0 : 8,
              }}
            >
              <img
                onClick={openTireSizeModal}
                src={userStore?.promotion?.images?.main ?? ""}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    objectFit: "contain",
                    paddingTop: 8,
                  }}
                >
                  <img
                    onClick={() => {
                      if (APP_ENV.appBrandId === 4) {
                        window.location.href = "/coupons";
                      } else {
                        openTireSizeModal();
                      }
                    }}
                    src={userStore?.promotion?.serviceTileImage ?? ""}
                    style={{ width: "100%", height: "auto" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    objectFit: "contain",
                    paddingTop: 8,
                  }}
                >
                  <img
                    onClick={() =>
                      window.open(appCreditInfo.applyNowLink, "_blank")
                    }
                    src={appCreditInfo.tileImage}
                    style={{ width: "100%", height: "auto" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StandardWidthContainer>
      )}
    </>
  );
};

export default MainHeroImage;
