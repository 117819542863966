import CartStore, { CART_ITEM_TYPE } from "../hooks/store/cart-store";
import { VENOM_APPT_SERVICE_TYPE } from "../hooks/store/appointment-store";
import ModalStore from "../hooks/store/modal-store";
import TireRotation from "../components/car-services/individual-services/tire-rotation";
import FlatRepair from "../components/car-services/individual-services/flat-repair";
import WheelAlignment from "../components/car-services/individual-services/wheel-alignment";
import VehicleFluidCheck from "../components/car-services/individual-services/vehicle-fluid-check";
import BrakeCheck from "../components/car-services/individual-services/brake-check";
import BatteryCheck from "../components/car-services/individual-services/battery-check";
import EngineCheck from "../components/car-services/individual-services/engine-check";
import CoolingSystemCheck from "../components/car-services/individual-services/cooling-system-check";
import TireConsultation from "../components/car-services/individual-services/tire-consultation";
import { VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE } from "../components/promotions/vyper/vyper-fifty-percent-off-oil-change";
import { VYPER_FREE_OIL_CHANGE } from "../components/promotions/vyper/vyper-free-oil-change";
import { VYPER_ESSENTIAL_PERSONNEL_DISCOUNT } from "../components/promotions/vyper/vyper-essential-personnel-discount";
import SMOG_CHECK_CONFIG from "./smog-check-config";
import { PROMO_THEME } from "../hooks/store/promotion-store";
import { VYPER_25_OFF_AC } from "../components/promotions/vyper/vyper-25-off-ac";
import { VYPER_AC_25_OFF_ICE_CREAM } from "../components/promotions/vyper/vyper-25-off-ac-with-ice-cream";
import { VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE_KROQ } from "../components/promotions/vyper/vyper-fifty-percent-off-oil-change-kroq";
import APP_ENV from "../appEnvironment";

export const CAR_SERVICES_ROUTE_DIRECTORY = "/car-services";

export const EDGE_ITEM_IDS = {
  uci: 127,
  tr: 65,
  flat: 82,
  labac: 41,
  vbi: 66,
  oc: 35272,
  ew: 33835,
  tireInstantDiscount: 63072,
  labas: 62435,
  cvi: 58977,
  silverRockAppt: 103822,
  shieldNetAppt: 104526,
};

export const createBasicCartItem = ({
  itemName = "",
  itemDescription = "",
  image = "",
  endPoint = "",
  Component = <></>,
  cartItemTypeId = CART_ITEM_TYPE.consultation,
  venomApptServiceTypeId = VENOM_APPT_SERVICE_TYPE.tireRotationFlats,
  edgeItemId = EDGE_ITEM_IDS.uci,
  edgeDescription = "",
  price = 0,
  _subtotal = 0,
  _packageSubtotal = 0,
  _invoicePrice = 0,
  promotion = null,
}) => ({
  itemName,
  itemDescription,
  image,
  endPoint,
  Component,
  cartItemTypeId,
  venomApptServiceTypeId,
  edgeItemId,
  edgeDescription: edgeDescription || itemName,
  price,
  _subtotal,
  _packageSubtotal,
  _invoicePrice,
  promotion,
});

const TIRE_ROTATION = createBasicCartItem({
  itemName: "Tire Rotation",
  itemDescription:
    "To avoid uneven tread wear it's important to rotate your tires regularly.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/tirerotation.svg",
  endPoint: `${CAR_SERVICES_ROUTE_DIRECTORY}/tire-rotation`,
  Component: TireRotation,
  cartItemTypeId: CART_ITEM_TYPE.freeService,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.tireRotationFlats,
  edgeItemId: EDGE_ITEM_IDS.tr,
  edgeDescription: "FREE Tire Rotation",
});

const FLAT_TIRE_REPAIR = createBasicCartItem({
  itemName: "Flat Tire Repair",
  itemDescription:
    "Flat Tire Repairs are always free. Even if you did not purchase the tires from us.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/tireservicesrepair.svg",
  endPoint: `${CAR_SERVICES_ROUTE_DIRECTORY}/flat-repair`,
  Component: FlatRepair,
  cartItemTypeId: CART_ITEM_TYPE.freeService,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.tireRotationFlats,
  edgeItemId: EDGE_ITEM_IDS.flat,
  edgeDescription: "FREE Flat Tire Repair",
});

const WHEEL_ALIGNMENT_CHECK = createBasicCartItem({
  itemName: "Wheel Alignment Check",
  itemDescription:
    "Wheel alignment is important for tire life and vehicle handling.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/wheelalignment.svg",
  endPoint: `${CAR_SERVICES_ROUTE_DIRECTORY}/wheel-alignment-check`,
  Component: WheelAlignment,
  cartItemTypeId: CART_ITEM_TYPE.freeService,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.mechanicalAlignment,
  edgeItemId: EDGE_ITEM_IDS.labac,
  edgeDescription: "FREE Wheel Alignment Check",
  promotion: createBasicCartItem({
    itemName: "Save $25 on Wheel Alignment Service (If needed)",
    edgeItemId: "",
    edgeDescription:
      "Save $25 on Wheel Alignment Service (If needed) - Code: MZ435AL",
  }),
});

const BRAKE_CHECK = createBasicCartItem({
  itemName: "Brake Inspection",
  itemDescription:
    "Our expert mechanics will assess your brake condition for free.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/brakeservices.svg",
  endPoint: `${CAR_SERVICES_ROUTE_DIRECTORY}/brake-check`,
  Component: BrakeCheck,
  cartItemTypeId: CART_ITEM_TYPE.freeService,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.mechanicalAlignment,
  edgeItemId: EDGE_ITEM_IDS.vbi,
  edgeDescription: "FREE Brake Check (Basic Inspection)",
  promotion: createBasicCartItem({
    itemName: "Save $50 on Brake Service (If needed)",
    edgeDescription: "Save $50 on Brake Service (If needed) - Code: B5WE8XY3",
  }),
});

const VEHICLE_FLIUD_CHECK = createBasicCartItem({
  itemName: "Vehicle Fluid Check",
  itemDescription:
    "Our technicians will check the level and condition of the important fluids in your vehicle.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/vehiclefluidservice.svg",
  endPoint: `${CAR_SERVICES_ROUTE_DIRECTORY}/vehicle-fluid-check`,
  Component: VehicleFluidCheck,
  cartItemTypeId: CART_ITEM_TYPE.freeService,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.tireRotationFlats,
  edgeItemId: EDGE_ITEM_IDS.uci,
  edgeDescription: "FREE Vehicle Fluid Check (Basic Inspection)",
});

const BATTERY_CHECK = createBasicCartItem({
  itemName: "Battery Check",
  itemDescription:
    "We can quickly check the condition of your battery using our state-of-art equipment.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/batteryservice.svg",
  endPoint: `${CAR_SERVICES_ROUTE_DIRECTORY}/battery-check`,
  Component: BatteryCheck,
  cartItemTypeId: CART_ITEM_TYPE.freeService,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.tireRotationFlats,
  edgeItemId: EDGE_ITEM_IDS.uci,
  edgeDescription: "FREE Battery Check (Basic Inspection)",
});

const ENGINE_CHECK = createBasicCartItem({
  itemName: "Engine Check",
  itemDescription:
    "Warning Light? Strange sound? We can can help with a basic engine check.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/engineservices.svg",
  endPoint: `${CAR_SERVICES_ROUTE_DIRECTORY}/engine-check`,
  Component: EngineCheck,
  cartItemTypeId: CART_ITEM_TYPE.freeService,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.mechanicalAlignment,
  edgeItemId: EDGE_ITEM_IDS.uci,
  edgeDescription: "FREE Engine Check (Basic Inspection)",
});

const COOLING_SYSTEM_CHECK = createBasicCartItem({
  itemName: "Cooling System Check",
  itemDescription:
    "Keep your engine, radiator and yourself at a comfortable temperature.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/coolingsystemservices.svg",
  endPoint: `${CAR_SERVICES_ROUTE_DIRECTORY}/cooling-system-check`,
  Component: CoolingSystemCheck,
  cartItemTypeId: CART_ITEM_TYPE.freeService,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.mechanicalAlignment,
  edgeItemId: EDGE_ITEM_IDS.uci,
  edgeDescription: "FREE Cooling System Check (Basic Inspection)",
});

const COMPREHENSIVE_VEHICLE_INSPECTION = createBasicCartItem({
  itemName: "Comprehensive Vehicle Inspection",
  itemDescription:
    "Make sure your vehicle is in tip-top shape with a full vehicle inspection.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images%20-%20homepage/ico-problems-duo.svg",
  endPoint: CAR_SERVICES_ROUTE_DIRECTORY,
  Component: <></>,
  cartItemTypeId: CART_ITEM_TYPE.freeService,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.tireRotationFlats,
  edgeItemId: EDGE_ITEM_IDS.cvi,
  edgeDescription: "Comprehensive Vehicle Inspection",
  promotion: createBasicCartItem({
    itemName: `FREE Service! ${PROMO_THEME.sitePromoName} Savings ($22.95 value)`,
    edgeDescription: "",
  }),
});

const SMOG_CHECK_NEVADA = createBasicCartItem({
  itemName: "Nevada SMOG Check & Certificate",
  itemDescription:
    "Reserve an appointment for your $24.95 Smog Check & Certificate.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/smog-check.svg",
  endPoint: SMOG_CHECK_CONFIG.endPoint,
  Component: <></>,
  cartItemTypeId: CART_ITEM_TYPE.smogCheck,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.tireRotationFlats,
  edgeItemId: SMOG_CHECK_CONFIG.package.edgeItemId,
  edgeDescription: "WEB ORDER - SMOG CHECK & CERTIFICATE - NEVADA",
  price: SMOG_CHECK_CONFIG.package.price,
  _subtotal: SMOG_CHECK_CONFIG.package.price,
  _packageSubtotal: SMOG_CHECK_CONFIG.package.price,
  _invoicePrice: SMOG_CHECK_CONFIG.package.price,
});

const SILVER_ROCK_WARRANTY_CONSULTATION = createBasicCartItem({
  itemName: "SilverRock Warranty Repair Consultation",
  itemDescription:
    "Reserve an appointment time for you Silver Rock Warranty Repair.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images%20-%20homepage/ico-problems-duo.svg",
  endPoint: `/service-partnerships/silver-rock`,
  Component: <></>,
  cartItemTypeId: CART_ITEM_TYPE.freeService,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.mechanicalAlignment,
  edgeItemId: EDGE_ITEM_IDS.silverRockAppt,
  edgeDescription: "SilverRock Warranty Repair Consultation",
});

const SHIELD_REPAIR_NETWORK_WARRANTY_CONSULTATION = createBasicCartItem({
  itemName: "Shield Repair Network Warranty Repair Consultation",
  itemDescription:
    "Reserve an appointment time for you Shield Repair Network Warranty Repair.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images%20-%20homepage/ico-problems-duo.svg",
  endPoint: `/service-partnerships/shield-repair-network`,
  Component: <></>,
  cartItemTypeId: CART_ITEM_TYPE.freeService,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.mechanicalAlignment,
  edgeItemId: EDGE_ITEM_IDS.shieldNetAppt,
  edgeDescription: "Shield Repair Network Warranty Repair Consultation",
});

const TIRE_CONSULTATION = createBasicCartItem({
  itemName: "Tire Consultation",
  itemDescription:
    "Reserve time with a tire expert to help choose the right tires for your vehicle and budget.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images%20-%20homepage/ico-online-duo.svg",
  endPoint: `${CAR_SERVICES_ROUTE_DIRECTORY}/tire-consultation`,
  Component: TireConsultation,
  cartItemTypeId: CART_ITEM_TYPE.consultation,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.tireRotationFlats,
  edgeItemId: EDGE_ITEM_IDS.uci,
  edgeDescription:
    "Tire Consultation Reservation (Review Tire Options In-Store)",
});

const OIL_CONSULTATION = createBasicCartItem({
  itemName: "Oil Change Reservation (Call for Vehicle Specific Quote)",
  itemDescription:
    "Reserve an appointment for your oil change. Includes a Free Tire Rotation and Free Vehicle Fluid Check.",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images%20-%20homepage/ico-oil-duo.svg",
  endPoint: "/oilrecommendations",
  Component: <></>,
  cartItemTypeId: CART_ITEM_TYPE.consultation,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.oilChange,
  edgeItemId: EDGE_ITEM_IDS.oc,
  edgeDescription: "Oil Change Reservation (Call for Vehicle Specific Quote)",
  promotion: createBasicCartItem({
    itemName: "Save $15 on Full Synthetic Oil Change",
    edgeDescription: "Save $15 on Full Synthetic Oil Change - Code: 4HG7KL9",
  }),
});

const OIL_CONSULTATION_CONVENTIONAL = {
  ...OIL_CONSULTATION,
  itemName:
    "Oil Change Reservation - Premium Conventional Oil (Call for Vehicle Specific Quote)",
  edgeDescription:
    "Oil Change Reservation - Conventional Oil Requested (Call for Vehicle Specific Quote)",
  sameAsBasicCartItem: "OIL_CONSULTATION",
};

const OIL_CONSULTATION_HIGHMILEAGE = {
  ...OIL_CONSULTATION,
  itemName:
    "Oil Change Reservation - High Mileage Oil (Call for Vehicle Specific Quote)",
  edgeDescription:
    "Oil Change Reservation - High Mileage Oil Requested (Call for Vehicle Specific Quote)",
  sameAsBasicCartItem: "OIL_CONSULTATION",
};

const OIL_CONSULTATION_FULLSYNTHETIC = {
  ...OIL_CONSULTATION,
  itemName:
    "Oil Change Reservation - Full Synthetic Oil (Call for Vehicle Specific Quote)",
  edgeDescription:
    "Oil Change Reservation - Full Synthetic Oil Requested (Call for Vehicle Specific Quote)",
  sameAsBasicCartItem: "OIL_CONSULTATION",
};

const FREE_OIL_CHANGE = createBasicCartItem({
  itemName: "FREE Oil Change & Tire Rotation (Up to $75 off)",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images%20-%20homepage/ico-online-duo.svg",
  cartItemTypeId: CART_ITEM_TYPE.promotion,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.oilChange,
  edgeItemId: VYPER_FREE_OIL_CHANGE.edgeItemId,
  edgeDescription: "FREE Oil Change & Tire Rotation (Up to $75 off)",
});

const FIFTY_PERCENT_OFF_OIL_CHANGE = createBasicCartItem({
  itemName:
    "SPECIAL PROMOTION - 50% off Oil Change Reservation (Call for Vehicle Specific Quote)",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images%20-%20homepage/ico-online-duo.svg",
  cartItemTypeId: CART_ITEM_TYPE.promotion,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.oilChange,
  edgeItemId: VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE.edgeItemId,
  edgeDescription: `SPECIAL PROMOTION - 50% off Oil Change Reservation (Call for Vehicle Specific Quote)`,
});

const FIFTY_PERCENT_OFF_OIL_CHANGE_KROQ = createBasicCartItem({
  itemName:
    "SPECIAL PROMOTION - 50% off Oil Change Reservation (Call for Vehicle Specific Quote)",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images%20-%20homepage/ico-online-duo.svg",
  cartItemTypeId: CART_ITEM_TYPE.promotion,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.oilChange,
  edgeItemId: VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE_KROQ.edgeItemId,
  edgeDescription: `SPECIAL PROMOTION - 50% off Oil Change Reservation (Call for Vehicle Specific Quote)`,
});

const ESSENTIAL_PERSONNEL_DISCOUNT = createBasicCartItem({
  itemName:
    "10% Off Any Service - Military, Uber/Lyft, & Union Member Discount (Cannot be combined w/ other offers)",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images%20-%20homepage/ico-online-duo.svg",
  cartItemTypeId: CART_ITEM_TYPE.promotion,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.tireRotationFlats,
  edgeItemId: VYPER_ESSENTIAL_PERSONNEL_DISCOUNT.edgeItemId,
  edgeDescription: `10% Off Any Service - Military, Uber/Lyft, & Union Member Discount (Cannot be combined w/ other offers)`,
});

const AC_SERVICE_25_OFF = createBasicCartItem({
  itemName: "FREE A/C Inspection (Save $25 on Service - If Needed)",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/coolingsystemservices.svg",
  cartItemTypeId: CART_ITEM_TYPE.promotion,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.mechanicalAlignment,
  edgeItemId: VYPER_25_OFF_AC.edgeItemId,
  edgeDescription: `FREE A/C Inspection (Save $25 on Service - If Needed)`,
});

const AC_SERVICE_25_OFF_WITH_ICE_CREAM = createBasicCartItem({
  itemName: `FREE A/C Inspection (Save $25 on Service - If Needed + $10 Handel's Ice Cream Gift Card)`,
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/coolingsystemservices.svg",
  cartItemTypeId: CART_ITEM_TYPE.promotion,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.mechanicalAlignment,
  edgeItemId: VYPER_AC_25_OFF_ICE_CREAM.edgeItemId,
  edgeDescription: `FREE A/C Inspection (Save $25 on Service - If Needed + $10 Handel's Ice Cream Gift Card)`,
});

const SAVE_HUNDRED_ON_BRAKES = createBasicCartItem({
  itemName:
    "Brake Inspection - Save $100 on Standard Brake Service (If Needed)",
  itemDescription:
    "Brake Inspection - Save $100 on Standard Brake Service (If Needed)",
  image:
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/content-images/brakeservices.svg",
  endPoint: `${CAR_SERVICES_ROUTE_DIRECTORY}/brake-check`,
  Component: <></>,
  cartItemTypeId: CART_ITEM_TYPE.freeService,
  venomApptServiceTypeId: VENOM_APPT_SERVICE_TYPE.mechanicalAlignment,
  edgeItemId: 104303,
  edgeDescription:
    "WEB COUPON - Save $100 on Standard Brake Service (If Needed)",
});

export const STATIC_CART_ITEMS = Object.assign(
  {},
  ...Object.entries({
    TIRE_ROTATION,
    FLAT_TIRE_REPAIR,
    WHEEL_ALIGNMENT_CHECK,
    BRAKE_CHECK,
    VEHICLE_FLIUD_CHECK,
    BATTERY_CHECK,
    ENGINE_CHECK,
    COOLING_SYSTEM_CHECK,
    COMPREHENSIVE_VEHICLE_INSPECTION,
    SAVE_HUNDRED_ON_BRAKES,
    SMOG_CHECK_NEVADA,
    SILVER_ROCK_WARRANTY_CONSULTATION,
    SHIELD_REPAIR_NETWORK_WARRANTY_CONSULTATION,
    TIRE_CONSULTATION,
    OIL_CONSULTATION,
    OIL_CONSULTATION_CONVENTIONAL,
    OIL_CONSULTATION_HIGHMILEAGE,
    OIL_CONSULTATION_FULLSYNTHETIC,
    FREE_OIL_CHANGE,
    FIFTY_PERCENT_OFF_OIL_CHANGE,
    FIFTY_PERCENT_OFF_OIL_CHANGE_KROQ,
    ESSENTIAL_PERSONNEL_DISCOUNT,
    AC_SERVICE_25_OFF,
    AC_SERVICE_25_OFF_WITH_ICE_CREAM,
  })
    .filter(([key]) => {
      if (
        APP_ENV.appBrandId === 4 &&
        (key === "OIL_CONSULTATION" || key === "BRAKE_CHECK")
      ) {
        return false;
      }
      return true;
    })
    .map(([key, val]) => {
      const [getCartItems, setCartItems] =
        CartStore.getState().useCartItemActions();

      const webPartNumber = val?.sameAsBasicCartItem ?? key;
      const webUniqueItemId = key;

      const itemIsInCart = () =>
        getCartItems().some((i) => i?.webPartNumber == webPartNumber);

      const removeFromCart = () => {
        setCartItems(
          getCartItems().filter((i) => i?.webPartNumber !== webPartNumber)
        );
      };

      const item = {
        ...val,
        webPartNumber,
        webUniqueItemId,
        itemIsInCart,
        removeFromCart,
      };

      return {
        [key]: {
          ...item,
          addToCart: async (showModal = true) => {
            await setCartItems([
              ...getCartItems().filter(
                (i) => i?.webPartNumber !== webPartNumber
              ),
              item,
            ]);
            if (showModal) {
              ModalStore.getState().CartDrawer.openModal();
            }
          },
        },
      };
    })
);
