import {
  Paper,
  MenuList,
  MenuItem,
  Typography,
  ListItemText,
  ListItemIcon,
  Box,
  Link,
  Button,
} from "@material-ui/core";
import DrawerOffsetTop from "../../modals/widgets/DrawerOffsetTop";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { useHistory } from "react-router";
import {
  QUICK_APPOINTMENT_DIRECTORY,
  TIRES_ROUTE_DIRECTORY,
} from "../../../routes";
import { CREDIT_PAGE_ENDPOINT } from "../../../hooks/store/credit-store";
import useOpenTireSizeSelector from "../../../hooks/util/useOpenTireSizeSelector";
import APP_ENV from "../../../appEnvironment";
import { useLocationStore } from "../../../hooks/store/location-store";
import OlarkChat from "../../../hooks/store/client/olark-chat";

const { theme } = APP_ENV;

const MenuDrawerMobile = ({ mobileHeaderHeight, isOpen, onClose }) => {
  const history = useHistory();

  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: true });

  const handleMenuItemClick = (link) => {
    onClose();
    if (link == TIRES_ROUTE_DIRECTORY) {
      openTireSizeModal();
      return;
    }
    history.push(link);
  };

  const openLiveChat = () => {
    onClose();
    OlarkChat.expand();
  };

  const [userStore] = useLocationStore((st) => [st.userStore]);

  const menuItemList = [
    ["Quick Appointment", QUICK_APPOINTMENT_DIRECTORY],
    ["Tires", TIRES_ROUTE_DIRECTORY],
    ["Wheels", "/wheels"],
    ["Oil Change", "/oilrecommendations"],
    ["Services", "/car-services"],
    userStore?.smogCheck?.isSmogCheckState
      ? ["Smog Check", userStore.smogCheck.endPoint]
      : null,
    ["Locations", "/locations"],
    ["Hot Deals", "/coupons"],
    [`${APP_ENV.appBrandName} Credit`, CREDIT_PAGE_ENDPOINT],
  ]
    .filter(
      (item) =>
        item &&
        (APP_ENV.appBrandId !== 4 ||
          !["Quick Appointment", "Services", "Oil Change"].includes(item[0]))
    )
    .filter((i) => Boolean(i));

  const submenuItems = [
    [`About ${APP_ENV.appBrandName}`, "/about-us"],
    ["Careers", "/careers"],
  ];

  return (
    <DrawerOffsetTop
      isOpen={isOpen}
      closeModal={onClose}
      PaperProps={{
        variant: "outlined",
        style: {
          top: mobileHeaderHeight,
          backgroundColor: theme.palette.background.default,
          width: "90vw",
          padding: 8,
        },
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 15,
        }}
      >
        <Link
          href={`tel:${APP_ENV.appPhoneNumber}`}
          style={{ fontWeight: "bold", fontSize: 16 }}
        >
          {APP_ENV.appPhoneNumber}
        </Link>
        <Button color="primary" onClick={openLiveChat}>
          Live Chat
        </Button>
      </Box>
      <Paper>
        <MenuList style={{ padding: 0 }}>
          {menuItemList.map(([label, link]) => {
            return (
              <MenuItem
                key={label}
                style={{
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  height: 50,
                }}
                onClick={() => handleMenuItemClick(link)}
              >
                <ListItemText>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    {label}
                  </Typography>
                </ListItemText>
                <ListItemIcon style={{ justifyContent: "flex-end" }}>
                  <ArrowForwardIosRoundedIcon style={{ fontSize: 16 }} />
                </ListItemIcon>
              </MenuItem>
            );
          })}
        </MenuList>
      </Paper>
      <Box
        style={{
          marginTop: 20,
        }}
      >
        <MenuList style={{ padding: 0 }}>
          {submenuItems.map(([label, link]) => {
            return (
              <MenuItem
                key={label}
                style={{
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  height: 50,
                }}
                onClick={() => handleMenuItemClick(link)}
              >
                <ListItemText>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    }}
                  >
                    {label}
                  </Typography>
                </ListItemText>
                <ListItemIcon style={{ justifyContent: "flex-end" }}>
                  <ArrowForwardIosRoundedIcon style={{ fontSize: 12 }} />
                </ListItemIcon>
              </MenuItem>
            );
          })}
        </MenuList>
      </Box>
    </DrawerOffsetTop>
  );
};

export default MenuDrawerMobile;
